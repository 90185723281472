import { MENU_KEYS } from 'constants/menus'
import { ASSIGNMENT_CREATE_PATH } from 'constants/routes'
import { EMPTY_ARR, ZERO } from 'constants/usefulConstants'
import { USER_STATUS_LIST } from 'constants/backend'
import React, { FunctionComponent } from 'react'
import { prop, map, pathOr, propEq, find, pluck, pipe, propOr, join } from 'ramda'
import { TUseDelete, TGetDataFromState, TData } from 'types'
import { TAssignmentItem } from 'types/models/assignment'
import { Menu } from 'components/Menu'
import Pagination from 'components/Pagination'
import {
  Table,
  TableActions,
  TableRow,
  TableHeader,
  TableCol,
  TableBody
} from 'components/Table'
import { Box, Dropdown, DropdownItem } from 'components/UI'
import { getFullName } from 'utils/get'
import { Approved, Rejected } from 'components/StyledElems'

type Props = {
    data: TGetDataFromState<TData<TAssignmentItem>>;
    filterAction: any;
    onEdit: (id) => void;
    deleteData: TUseDelete;
}

const AssignmentList: FunctionComponent<Props> = props => {
  const { data, onEdit, deleteData } = props
  const count = pathOr(ZERO, ['data', 'pagination', 'totalItems'], data)
  const list = pathOr(EMPTY_ARR, ['data', 'data'], data)
  const ids = map(prop('_id'), list)
  const actions = (
    <TableActions
      createPath={ASSIGNMENT_CREATE_PATH}
    />
  )

  return (
    <div>
      <Menu title="Пользователи" module={MENU_KEYS.ASSIGNMENT} active={MENU_KEYS.ASSIGNMENT} />
      <Box>
        <Table loading={data.loading} list={ids} actions={actions} gutter={30}>
          <TableHeader>
            <TableRow>
              <TableCol span={1}>#</TableCol>
              <TableCol span={3}>Email</TableCol>
              <TableCol span={4}>Email</TableCol>
              <TableCol span={4}>Имя</TableCol>
              <TableCol span={3}>Номер телефона</TableCol>
              <TableCol span={3}>Статус</TableCol>
              <TableCol span={1}> </TableCol>
            </TableRow>
          </TableHeader>
          <TableBody>
            {list.map((item: any, index) => {
              const email = prop('email', item)
              const _id = prop('_id', item)
              const phoneNumber = prop('phoneNumber', item)
              const status = prop('status', item)
              const clinics = pipe(
                propOr([], 'clinics'),
                pluck('name')
              )(item)
              const statusName = prop('name', find<any>(propEq('id', status), USER_STATUS_LIST))
              const name = getFullName(item)

              return (
                <TableRow key={index} align="center">
                  <TableCol span={1}>{index + 1}</TableCol>
                  <TableCol span={3}>{email}</TableCol>
                  <TableCol span={4}>{clinics.map(clinic => <Approved key={clinic} style={{ margin: '2px' }}>{clinic}</Approved>)}</TableCol>
                  <TableCol span={4}>{name}</TableCol>
                  <TableCol span={3}>{phoneNumber}</TableCol>
                  <TableCol span={3}>
                    {status === 'active' ? (
                      <Approved>
                        {statusName}
                      </Approved>
                    ) : status === 'blocked' ? (
                      <Rejected>
                        {statusName}
                      </Rejected>
                    ) : ''}
                  </TableCol>
                  <TableCol span={1}>
                    <Dropdown>
                      <DropdownItem onClick={() => onEdit(_id)}>
                        Изменить
                      </DropdownItem>
                      <DropdownItem onClick={() => deleteData.onSubmit(_id)}>
                        Удалить
                      </DropdownItem>
                    </Dropdown>
                  </TableCol>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Box>
      <Pagination count={count} />
    </div>
  )
}

export default AssignmentList
